<template>
  <div class="navmenu">
    <el-row class="navmenu_row" type="flex" align="middle" justify="start">
      <el-col class="navmenu_col" :span="18" :offset="2">
        <el-row type="flex" align="middle">
          <el-col :span="2" class="nav_item"
            ><a href="/">{{ $t('home') }}</a></el-col
          >

          <el-col :span="3" class="nav_item">
            {{ $t('aboutUs') }}

            <div class="sub_nav">
              <ul>
                <li>
                  <a href="/fazhan.html">{{ $t('developmentPath') }}</a>
                </li>
                <!-- <li>
                  <a href="/hexin.html">{{ $t('coreMember') }}</a>
                </li>
                <li>
                  <a href="/heying.html">{{ $t('groupPhoto') }}</a>
                </li> -->
              </ul>
            </div>
          </el-col>
          <el-col :span="3" class="nav_item">
            {{ $t('rDPipeline') }}
            <div class="sub_nav">
              <ul>
                <li>
                  <a href="/pipeline.html">{{ $t('pipelineDiagram') }}</a>
                </li>
                <li>
                  <a href="/unique.html">{{ $t('fiveAdvantages') }}</a>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="3" class="nav_item">
            {{ $t('scientificInnovation') }}

            <div class="sub_nav">
              <ul>
                <!-- <li>
                  <a href="/experts.html">{{ $t('committeeOfExperts') }}</a>
                </li> -->
                <li><a href="/tuandui.html">JIA-LAB</a></li>
              </ul>
            </div>
          </el-col>
          <!-- <el-col :span="3" class="nav_item">
            {{ $t('careers') }}

            <div class="sub_nav">
              <ul>
                <li>
                  <a href="/joinus.html">{{ $t('recruitmentNotice') }}</a>
                </li>
              </ul>
            </div>
          </el-col> -->

          <el-col :span="4" class="nav_item">
            {{ $t('newsAndCooperation') }}

            <div class="sub_nav">
              <ul>
                <li>
                  <a href="/hezuofang.html">{{ $t('partner') }}</a>
                </li>

                <li>
                  <a href="https://mp.xuedaoapp.com/admin/login">代理方</a>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import db from '@feiyan-cli/db'
  export default {
    methods: {
      changeLanguage(key) {
        this.$i18n.locale = key
        db.ls.set('lang', key)
      }
    }
  }
</script>
<style lang="less" scoped>
  .navmenu {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-color: #222;
    width: 100%;
    height: 77px;
    .navmenu_row {
      height: 77px;
      .navmenu_col {
        height: 55px;
        color: #fff;
        font-size: 16px;

        .logo {
          height: 55px;
          transform: scale(2.7);
          padding-left: 20px;
        }

        .nav_item {
          position: relative;
          text-align: center;
          padding: 20px 0;
          .lang {
            cursor: pointer;
            span {
              margin: 0 5px;
            }
          }
          a {
            text-decoration: none;
            color: #fff;
          }
          &:hover {
            & .sub_nav {
              display: block;
            }
          }
          .sub_nav {
            display: none;
            position: absolute;
            //width: 100px;
            background: rgba(0, 0, 0, 0.35);
            margin-top: 10px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            color: #fff;
            line-height: 46px;
            padding: 10px 0;
            a {
              padding: 0 20px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
