<template>
  <header class="detail_header">{{ title }}</header>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '发展历程'
      }
    }
  }
</script>
<style lang="less" scoped>
  .detail_header {
    height: 47px;
    background: #206ed2;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 80px;
    color: #fff;
    font-size: 25px;
    position: relative;
    margin-bottom: 50px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      width: 13px;
      height: 13px;
      background: #fff;
      border-radius: 50%;
    }
  }
</style>
