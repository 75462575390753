import Vue from 'vue'
import VueI18n from 'vue-i18n'
import db from '@feiyan-cli/db'
Vue.use(VueI18n)
// 引入各个语言配置文件
import zh from './zh'
import en from './en'
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  // locale: navigator.language ? navigator.language : 'zh-CN', // 语言标识
  locale: db.ls.get('lang') ? db.ls.get('lang') : 'zh-CN', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    'zh-CN': zh,
    en
  }
})
// 暴露i18n
export default i18n
