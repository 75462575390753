const en = {
  home: 'Home',
  aboutShenjiChanghua: 'About SineuGene',
  aboutShenjiChanghuaDesc:
    "SineuGene Therapeutics Co.,ltd. (hereinafter referred to as \"SineuGene\") is located in Life Science Park of Zhongguancun, Chang Ping District, Beijing. As a biotechnology and gene therapy company, SineuGene devotes its all innovation and technology resources to improve patients’ health and its mission is to develop and deliver life-changing gene therapies to people around the world. The co-founders of SineuGene are all from Tsinghua University. More than 10-year of a commitment to excellence in basic research and development in Tsinghua university makes SineuGene take an early step in understanding the pathogenesis of CNS diseases and AAV Vector screening technologies. Gene therapy technology, including AAV-mediated gene expression and editing, and small nucleic acid drugs are developed in SineuGene to achieve the intervention of target genes. It is mainly focusing on neurological diseases, especially neurodegenerative diseases, such as amyotrophic lateral sclerosis (ALS), stroke ( Stoke), Parkinson's disease (PD), Alzheimer'sdisease (AD), Huntington's disease (HD), autism spectrum disorder (ASD). SineuGene is expected to eventually solve these major medical and health problems for human beings.",
  aboutUs: 'About us',
  developmentPath: 'Development Process',
  coreMember: 'Leadership Team',
  coFounder: 'Co-Founders',
  Yichang: 'Yichang Jia',
  YichangDesc: `Yichang got his Ph.D. degree from Institute of Neuroscience, CAS. He used mouse genetics to study the pathogenesis of neurodegenerative diseases at the Jackson Institute of Mouse Genetics in 2007. He also was awarded the Independent Scientific Researcher Award by the American Institute of Health in 2012 and won the first prize of Shanghai Science and Technology Progress Award in the same year. After that, he won the second prize of NNSA in 2014. As the first author and corresponding author, he had published many academic articles in internationally renowned academic journals such as "Nature", "Nature Neuroscience", "Cell" and "Brain".`,
  YichangPost: '•Yichang is co-founder and the chairman of the Scientific Advisory Board.',
  pengLin: 'Lin Peng',
  pengLinDesc:
    'Lin got his Ph.D. degree from Tsinghua University. He had been working as an visiting scholar at School of Engineering in Purdue University. Lin had worked at School of Life Sciences of Tsinghua University and Westlake Institute for Advanced Study(WIAS).He worked as Assistant to Dean(Yigong Shi) and participated in the preparation of Westlake University. Before Joined SineuGene, He was the Chief Executive Officer of Nanjing Tianyin Health Development Co., Ltd. Lin had received rigorous scientific research training in the field of molecular biology and rich experience in entrepreneurship and business management.',
  pengLinPost: 'Lin is Co-founder and Chief Executive Officer(CEO)',

  guoWei: 'Wei Guo',
  guoWeiDesc:
    'Wei got Ph.D. degree in Shanghai Institute of Neuroscience, CAS. He also worked as post-doctoral fellow at School of Life Sciences, Tsinghua University. He worked as a research scientist at GSK. Before Joined SineuGene, he was a Research Associated Professor at Tsinghua University, and was in charge of a research team of more than 30 people. He has extensive research experience in neurodegenerative diseases and innovative drug development. He has published more than 10 academic papers and a number of international PCT patents. He has also participated in the establishment of a number of innovative pharmaceutical companies and helped to establish R&D pipelines.',
  chairOfTheScientificCommittee: 'Chairman of the Scientific Advisory Board',
  guoWeiPost: 'Wei is Co-founder and Chief Scientific Officer(CSO).',

  groupPhoto: 'Group photo',
  rDPipeline: 'R & D pipeline',
  pipelineDiagram: 'Pipeline diagram',
  fiveAdvantages: 'Unique Advantages',
  originalTarget: 'Original Target',
  originalTargetDesc: `The innovative knowledge team has been deeply involved in neurodegenerative diseases represented by ALS for more than ten years`,

  patentProtection: 'Patent Protection',
  patentProtectionDesc:
    'Innovative targets, PCT patent protection, international first-in-class drug research and development.',
  uniqueAnimalModel: 'Unique Animal Model',
  uniqueAnimalModelDesc:
    'The most advanced ALS disease model in the world, as well as genetic target screening based on disease model.',
  comprehensiveLayout: 'Comprehensive Layout',
  comprehensiveLayoutDesc:
    'Innovative drug targets, pathogenic gene editing, and screening of new AAV serotypes.',
  gatherAllForces: 'Gather All Forces',
  gatherAllForcesDesc:
    'Cooperate with ALS clinicians, patients, and social institutions to promote drug research and development.',
  scientificInnovation: 'Scientific Innovation',
  committeeOfExperts: 'Scientific Advisory Committee',
  jiaYichang: 'Yichang Jia',
  jiaYichangDesc:
    'PI at School of Medicine Tsinghua University \n McGovern Brain Institute Researcher',
  wangYizheng: 'Yizheng Wang',
  wangYizhengDesc: 'Academician of Chinese Academy of Sciences',
  caiLei: 'Cai Lei',
  caiLeiDesc: 'Vice president of JD',
  linXin: 'Xin Lin',
  linXinDesc: 'Professor at School of Medicine, Tsinghua University',
  hanLanqing: 'Lanqing Han',
  hanLanqingDesc: 'Chairman of Cyagen (Guangzhou) Biotechnology Co., Ltd',
  liHuapeng: 'Huapeng Li',
  liHuapengDesc: 'Chairman of PackGene (Guangzhou) Biotechnology Co., Ltd',
  cooperativeResearchTeam: 'Cooperative Scientific Research Team',
  tsinghuaUniversity: 'Tsinghua University',
  tsinghuaUniversityHospital: 'Tsinghua University Hospital',
  unitedCenter: 'Tsinghua / Peking University Life United Center',
  tsinghuaMcGovernInstituteForBrainResearch: 'Tsinghua McGovern Brain Institute',
  tsinghuaBrainAndIntelligenceLaboratory: 'Tsinghua brain and Intelligence Laboratory',
  recruitmentNotice: 'Career Opportunities',
  careers: 'Join Us',
  newsAndCooperation: 'News and Cooperation',
  news: 'News And Information',
  contactUs: 'Contact Us',
  partner: 'PARTNER',
  title1:
    'Department of Clinical Medicine: New Drug Registration Director/Manager Recruit one person',
  title2:
    'Department of Clinical Medicine: New Drug Registration Specialist \n' + 'Recruit one person',
  title3: 'Department of Clinical Medicine: Clinical Manager  \n' + 'Recruit one person',
  title4: 'Department of Clinical Medicine: Clinical specialist  \n' + 'Recruit one person',
  title5: 'Process Development Department: CMC Engineer  \n' + 'Recruit one person',
  title6: 'Process Development Department: CMC assistant engineer\n' + 'Recruit one person',
  title7: 'R &D department: Biopharmaceutical Researchers  \n' + 'Recruit 2-3 person',
  title8: 'R &D department: Biopharmaceutical Associate Researchers\n' + 'Recruit 3 person',
  title9: 'R &D department: Biopharmaceutical Assistant Researchers  \n' + 'Recruit Several person',
  title10: 'R &D department:  ELISA Kit researcher  \n' + 'Recruit one person',
  title11:
    'R &D department: Animal Experimenter (Assistant Researcher)  \n' + 'Recruit Several person',
  title12: 'R &D department: Drosophila Model Researcher  \n' + 'Recruit one person',
  spokenLanguage: 'English',
  language: {
    zh: '中文',
    en: 'English'
  }
}

const news = {
  news_title1: 'Scientific Advisory Board Inauguration Ceremony and Gene Therapy Forum Held',
  news_title2: 'Scientific Advisory Board Inauguration Ceremony and Gene Therapy Forum Held',
  news_title3: 'SineuGene Closed Over 10 Million Seed Round of Funding',
  news_title4: 'Promote the Development of Mental Diseases and Bring Benefits to Patients with ALS',
  news_btn: 'More Details'
}

const footer = {
  address:
    '101, 1F, Block B, Innovation Building, No. 29 Shengmingyuan Road, Changping District,, Beijing',
  bt: 'Copyright©2022 SineuGene Therapeutics Co.,ltd Copyright Beijing ICP No. 2022006423'
}
export default {
  ...en,
  ...news,
  ...footer
}
