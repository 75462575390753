<template>
  <div class="box">
    <el-row type="flex" justify="center">
      <el-col :lg="23" :xl="23" :md="24">
        <header class="header">
          <NavMenu />

          <!-- <template v-if="isIndexPage">
            <img :src="textPng" class="header_swiper" alt="" />
            <div class="container"></div>
          </template>

          <template v-else>
            <img :src="emptyPng" class="header_swiper" />
          </template> -->
        </header>

        <main class="main">
          <slot></slot>
          <Footer />
        </main>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import NavMenu from '@components/NavMenu'
  import Footer from '@components/Footer'

  export default {
    components: {
      NavMenu,
      Footer
    },
    data() {
      return {
        // textPng: require('../../assets/imgs/header_swiper.png'),
        textPng: require('../../assets/imgs/header_swiper_new.png'),
        emptyPng: require('../../assets/imgs/header_swiper_empty.png')
      }
    },
    computed: {
      isIndexPage() {
        return window.location.pathname == '/'
      },
      isFazhanPage() {
        return window.location.pathname == '/fazhan.html'
      }
    },

    created() {
      console.log(this.isIndex)
    }
  }
</script>

<style lang="less" scoped>
  .box {
    .header {
      .header_swiper {
        width: 100%;
        display: block;
      }
    }
    .main {
      background: #fdfdfd;
      min-height: 300px;
      padding: 105px 165px 0;
    }
  }
</style>
