const zh = {
  home: '首页',
  aboutShenjiChanghua: '关于学道科技',
  aboutShenjiChanghuaDesc:
    '郑州学道网络科技有限公司（简称“学道科技”）是一家新新兴的网络科技公司。公司已发展为己任，不断创造，不断创新，以科技为动力，以发展为导向的新时代网络科技公司',
  aboutUs: '关于我们',
  developmentPath: '发展历程',
  coreMember: '核心成员',
  coFounder: '联合创始人',
  Yichang: '贾怡昌',
  YichangDesc:
    '中科院神经科学研究所博士，神经退行性疾病研究领域专家。2007年在美国小鼠遗传圣地杰克逊研究所，利用小鼠遗传学研究神经退行性疾病的发病机制。2012年获美国健康研究院颁发的独立科学研究人奖(K99/R0O, pathway to independent award)。2012年上海市科技进步一等奖，2014年获国家自然科学二等奖。《Nature Neuroscience》、《Cell》和《Brain》等国际知名学术期刊发表多篇学术文章。',
  pengLin: '彭林',
  pengLinDesc:
    '清华大学的博士，普渡大学工学院访问学者。曾先后在清华大学生命科学学院、浙江西湖高等研究院等单位工作，在西湖高研院任施一公院长助理，参与筹备西湖大学具体工作。全职加入公司前，任“江宁药谷”开发办主任兼南京天印健康开发有限公司法人、总经理。具有很强的开拓精神和领导力，在分子生物学领域接受过严格的科研训练，有丰富的创业及企业管理经验。',
  pengLinPost: 'CEO',

  guoWei: '郭炜',
  guoWeiDesc:
    '中科院上海神经科学研究所博士，清华大学生命学院博士后。曾先后在葛兰素史克(上海)医药研发有限公司以及清华大学医学院和药学院从事药物研发工作。加入公司前，任清华大学药学院副研究员,并负责管理30多人的科研团队。在神经退行性疾病研究和创新药物研发\n等方面具有丰富的经验，发表10多篇学术论文和多项国际专利。还曾参与创建多家创新药企并帮助建立研发管线,具有很强的开拓精神和良好的企业家素养。',
  chairOfTheScientificCommittee: '科学委员会主席',
  guoWeiPost: 'CSO',
  groupPhoto: '集体合影',
  rDPipeline: '研发管线',
  pipelineDiagram: '管线图',
  fiveAdvantages: '五大优势',
  originalTarget: '原创靶点',
  originalTargetDesc: '清华大学原始创新知识成果团队深耕ALS为代表的神经退行性疾病十余年',
  patentProtection: '专利保护',
  patentProtectionDesc: '创新建立靶点，PCT专利保护，国际First-in-class药物研发',
  uniqueAnimalModel: '独有动物模型',
  uniqueAnimalModelDesc: '国际最先进的ALS疾病模型以及基于疾病模型的遗传学靶点筛选',
  comprehensiveLayout: '全面布局',
  comprehensiveLayoutDesc: '疾病治疗全面布局：创新药物靶点、致病基因编辑、以及新型AAV血清型筛选',
  gatherAllForces: '凝聚各方力量',
  gatherAllForcesDesc: '与ALS临床医生、患者、以及社会机构密切合作，合全社会力量推动药物研发。',
  scientificInnovation: '科学创新',
  committeeOfExperts: '专家委员会',
  jiaYichang: '贾怡昌',
  jiaYichangDesc: '清华大学医学院清华McGovern 研究员',
  wangYizheng: '王以政',
  wangYizhengDesc: '中国科学院院士',
  caiLei: '蔡  磊',
  caiLeiDesc: '京东副总裁',
  linXin: '林  欣',
  linXinDesc: '清华大学医学院 教授基础医学系主任',
  hanLanqing: '韩蓝青',
  hanLanqingDesc: '赛业（广州）生物科技有限公司 董事长',
  liHuapeng: '李华鹏',
  liHuapengDesc: '广州派真生物技术有限公司 董事长',
  cooperativeResearchTeam: '合作科研团队',
  tsinghuaUniversity: '清华大学',
  tsinghuaUniversityHospital: '清华大学医院',
  unitedCenter: '清华/北大生命联合中心',
  tsinghuaMcGovernInstituteForBrainResearch: '清华McGovern脑研究所',
  tsinghuaBrainAndIntelligenceLaboratory: '清华脑与智能实验室',
  careers: '招贤纳士',
  recruitmentNotice: '招聘启事',
  newsAndCooperation: '新闻与合作',
  news: '新闻资讯',
  partner: '合作方',
  contactUs: '联系我们',
  title1: '临床及医学事务部：新药注册总监/经理 1名',
  title2: '临床及医学事务部：新药注册 专员 1名',
  title3: '临床及医学事务部：临床经理 1名',
  title4: '临床及医学事务部：临床专员 1名',
  title5: '工艺开发部：CMC 工程师 1名',
  title6: '工艺开发部：CMC 助理工程师 1名',
  title7: '研发部：生物制药研究员 2-3名',
  title8: '研发部：生物制药副研究员 3名',
  title9: '研发部：生物制药助理研究员 若干名',
  title10: '研发部：ELISA试剂盒 研究员 1名',
  title11: '研发部：动物实验员（助理研究员）若干名',
  title12: '研发部：果蝇模型研究员 1名',
  spokenLanguage: '中文',
  language: {
    zh: '中文',
    en: 'English'
  }
}

const news = {
  news_title1: '科学顾问委员会成立仪式暨基因治疗论坛举行',
  news_title2: '科学顾问委员会成立仪式暨基因治疗论坛举行',
  news_title3: '神济昌华完成千万级种子轮融资',
  news_title4: '推动精神疾病发展给渐冻症患者带来福音',
  news_btn: '查看详情'
}

const footer = {
  address: '北京市昌平区生命园路29号创新大厦B101',
  bt: 'Copyright © 2022 神济昌华（北京）生物科技有限公司 版权所有 京ICP备2022006423号'
}
export default {
  ...zh,
  ...news,
  ...footer
}
