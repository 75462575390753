<template>
  <div class="footer">
    <div class="to_top" @click="handleToTop"></div>
    <h1 class="footer_title">{{ $t('contactUs') }}</h1>

    <main class="main">
      <div class="left">
        <img src="../../assets/imgs/erweima.jpg" alt="" class="qrcode" />
      </div>
      <div class="line"></div>
      <div class="contact">
        <p>
          <img src="../../assets/imgs/icon_phone.png" class="icon icon_phone" alt="" /> :
          15239481297
        </p>
        <p>
          <img src="../../assets/imgs/email_icon.png" class="icon icon_email" alt="" /> :
          879464294@qq.com
        </p>
        <p :class="{ en_detail: isEnglish }">
          <img src="../../assets/imgs/location_icon.png" class="icon icon_location" alt="" /> :

          <span>郑州市高新技术产业开发区瑞达路96号</span>
          <!-- 北京市昌平区生命园路29号创新大厦B101 -->
        </p>
      </div>
    </main>

    <footer class="beian">
      <p>
        <!-- {{ $t('bt') }} -->
        <!-- Copyright © 2022 郑州学道网络科技有限公司 版权所有 -->
        <a href="http://beian.miit.gov.cn/">豫ICP备2022014851号-1</a>
      </p>
    </footer>
  </div>
</template>

<script>
  export default {
    methods: {
      handleToTop() {
        document.getElementsByClassName('navmenu')[0].scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    computed: {
      isEnglish() {
        return this.$i18n.locale == 'en'
      }
    }
  }
</script>

<style lang="less" scoped>
  .footer {
    height: 454px;
    margin: 0 -165px;
    background-color: #222;
    box-sizing: border-box;
    padding-top: 72px;
    position: relative;

    .to_top {
      position: absolute;
      right: 30px;
      top: -60px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      background: url(../../assets/imgs/to_top.png) no-repeat center;
      background-size: 100% 100%;
    }

    .footer_title {
      font-size: 34px;
      text-align: center;
      font-weight: border;
      color: #fff;
      margin-bottom: 40px;
    }

    .main {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      .left {
        width: 49%;
        text-align: right;
        .qrcode {
          width: 169px;
          height: 169px;
          display: inline-block;
        }
      }

      .line {
        width: 1px;
        height: 128px;
        background: #484747;
        margin: 0 77px;
      }

      .contact {
        width: 49%;
        height: 169px;
        font-size: 17px;
        .icon {
          display: inline-block;
          vertical-align: middle;
        }

        .icon_phone {
          width: 23px;
        }
        .icon_email {
          width: 21px;
          // height: 16px;
        }
        .icon_location {
          width: 17px;
          // height: 24px;
        }
        p {
          margin-top: 30px;
          color: #6b6b6b;
        }

        .en_detail {
          // font-size: 13px;

          > span {
            display: inline-block;
            width: 90%;
            vertical-align: text-top;
          }
        }
      }
    }

    .beian {
      text-align: center;
      font-size: 17px;
      p {
        margin-top: 14px;
        a {
          text-decoration: none;
          font-size: 12px;
          color: #6b6b6b;
        }
      }
    }
  }
</style>
